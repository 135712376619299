import axios from 'axios'

import { SignatureRequest } from './signature'
import { InfiniteQuery } from 'types/product'

export const getMintProducts = async ({ url, msg, sig }: { url: string } & SignatureRequest & InfiniteQuery) => {
  const res = await axios.get(url, {
    headers: {
      msg,
      sig
    }
  })

  return res.data
}

export const editMintProduct = async ({
  productId,
  data,
  msg,
  sig
}: { productId: string; data: { name: string; description: string } } & SignatureRequest) => {
  const res = await axios.patch(`v2/products/${productId}`, data, {
    headers: {
      msg: JSON.stringify(msg),
      sig
    }
  })
  return res.data
}

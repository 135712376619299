import { Contract } from '@ethersproject/contracts'
import { useQuery, useQueryClient } from 'react-query'

import { networkProvider } from 'hooks/useContract'
import { Detail } from 'types/bunny'
import { bunnyNFTAddress, bunnyMetadataUrl } from 'config/constantKey'

import bunnyABI from 'config/abis/bunnyNFT.json'

const bunnyContract = new Contract(bunnyNFTAddress || '', bunnyABI, networkProvider)

export const getBunnyDetail = (tokenId: number): Promise<Detail> => {
  return fetch(bunnyMetadataUrl + `/tokens/${tokenId}`).then(res => res.json())
}

export const getMyBunnies = async (account: string) => {
  if (account) {
    const balance = await bunnyContract.balanceOf(account)
    const total = +balance.toString() ?? 0
    const promises = []

    for (let i = 0; i < total; i++) {
      promises.push(bunnyContract.tokenOfOwnerByIndex(account, i))
    }

    const settled = await Promise.allSettled(promises)
    const myTokens = settled
      .map(item => {
        if (item.status === 'fulfilled') {
          return item.value
        }

        return undefined
      })
      .filter(x => x)
      .sort((a, b) => a - b)

    return myTokens
  }
}

export const getMyBunnyDetails = async (account: string) => {
  const myBunnies = await getMyBunnies(account)
  if (myBunnies && myBunnies.length > 0) {
    const promises: Promise<Detail>[] = []

    myBunnies.forEach(bunny => promises.push(getBunnyDetail(bunny)))

    const details = await Promise.all(promises)
    return details.map((detail, index) => ({
      ...detail,
      tokenId: myBunnies[index].toString()
    }))
  }

  return []
}

export const myBunnyDetailsQueryKey = (account: string) => `@my_bunny_details/${account}`
export const useMyBunnyDetails = (account: string) => {
  const queryClient = useQueryClient()

  return {
    ...useQuery<Detail[]>(myBunnyDetailsQueryKey(account), () => getMyBunnyDetails(account), {
      enabled: !!account
    }),
    invalidate: () => queryClient.invalidateQueries(myBunnyDetailsQueryKey(account))
  }
}

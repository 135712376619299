import React, { useState, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import clsx from 'classnames'

import ProfileDropdown from './ProfileDropdown'
// import SwitchLanguage from './SwitchLanguage'
import HomeMobileNav from './HomeMobileNav'

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const [mobileMenuOpen, setMenuMobileOpen] = useState(false)

  const handleToggle = useCallback(() => {
    setMenuMobileOpen(open => !open)
  }, [])

  return (
    <header className='header'>
      <div className='header-actions'>
        <NavLink to='/'>
          <div className='logo ml-3 sm:ml-6'>
            <img src='/images/trophee-eth-header-logo.svg' className='w-[148px] sm:w-[166px] h-12' alt='logo' />
          </div>
        </NavLink>

        {/* <div className='hidden sm:block ml-10'>
          <SwitchLanguage />
        </div> */}
      </div>

      <div className='header-actions'>
        <nav className='hidden sm:flex navigation items-center'>
          <ul className='menu-main'>
            <li className='menu-main-item'>
              <NavLink className='menu-main-item-link' activeClassName='border-b-2 border-new-lighter' to='/manga-drop'>
                <Trans>MangaDrop</Trans>
              </NavLink>
            </li>

            {/* <li className='menu-main-item'>
              <NavLink className='menu-main-item-link' activeClassName='border-b-2 border-new-lighter' to='/features'>
                <Trans>Features</Trans>
              </NavLink>
            </li> */}

            <li className='menu-main-item'>
              <NavLink
                className='menu-main-item-link'
                activeClassName='border-b-2 border-new-lighter'
                to='/marketplace'
              >
                <Trans>Marketplace</Trans>
              </NavLink>
            </li>

            {/* <li className='menu-main-item'>
              <a className='menu-main-item-link' href='https://farm.trophee.xyz'>
                <Trans>Farms</Trans>
              </a>
            </li> */}

            <li className='menu-main-item'>
              <span className='menu-main-item-link disabled opacity-50 leading-none'>
                <Trans>FAQ</Trans>
              </span>
              {/* <NavLink className='menu-main-item-link' activeClassName='border-b-2 border-new-lighter' to='/faq'>
                <Trans>FAQ</Trans>
              </NavLink> */}
            </li>
          </ul>
        </nav>

        <div
          className={clsx('fixed top-[60px] sm:top-[80px] left-0 w-full sm:w-[326px] z-10 transition-all ease-linear', {
            'left-[-1000px]': !mobileMenuOpen
          })}
        >
          <HomeMobileNav open={mobileMenuOpen} onToggle={handleToggle} />
        </div>

        <div className='hidden sm:block w-[1px] h-12 bg-new-darker'></div>
        <div className='flex items-center'>
          <ProfileDropdown />
          <img
            className='sm:hidden w-4 h-4 mr-4'
            src='/images/marketplace/hamburger.png'
            alt='profile'
            onClick={handleToggle}
          />
        </div>
      </div>
    </header>
  )
}

export default Header

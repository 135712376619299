import React from 'react'
import { explorerUrl } from 'utils/metamask'

export interface ToastSuccessProps {
  header?: string
  message: string
  tx?: string
}

const ToastSuccess: React.FC<ToastSuccessProps> = ({ header, message, tx }) => {
  return (
    <div>
      <p className='font-medium'>{header || 'Order Success with tx:'}</p>
      <a href={`${explorerUrl}/tx/${tx}`} target='_blank' rel='noreferrer'>
        <p className='w-72 mt-1 truncate hover:text-primary font-normal'>{message}</p>
      </a>
    </div>
  )
}

export default ToastSuccess

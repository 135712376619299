import React from 'react'
import SelectRaw from 'react-select'
import { FieldErrors } from 'react-hook-form'
import { SelectComponentsProps } from 'react-select/src/Select'

interface SelectPropsTest extends SelectComponentsProps {
  errors?: FieldErrors
}

const getStyle = (error?: string) => {
  return {
    control: (provided: any) => ({
      ...provided,
      borderColor: !error ? 'rgb(230, 232, 236)' : 'red',
      height: 54,
      minHeight: 54
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    menu: (base: any) => ({ ...base, zIndex: 20 })
  }
}

const Select = React.forwardRef<SelectRaw, SelectPropsTest>(
  ({ errors, name = {}, ...others }: SelectPropsTest, ref) => {
    const error = name && errors?.[name]?.message
    return (
      <>
        <SelectRaw {...others} styles={getStyle(error)} ref={ref} />
        {!!error && <p className='text-red-500 mt-2'>{error}</p>}
      </>
    )
  }
)

export default Select

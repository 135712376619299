import axios from 'axios'
import { Contract } from '@ethersproject/contracts'
import round from 'lodash/round'

import { networkProvider } from 'hooks'
import { SignatureRequest } from './signature'
import { NFTOrder, OrderRequest, OrderRequestResponse, OrderType, P2POrder, WyvernOrder } from 'types/order'
import { InfiniteQuery, ProductMetadata } from 'types/product'
import { queryClient } from '../App'

import { MANGA_ADDRESS, MEMBERSHIP_LEVEL1_ID, MEMBERSHIP_NFT_ADDRESS } from 'config/constantKey'
import ERC1155_ABI from 'config/abis/erc1155Abi.json'
import ERC20_ABI from 'config/abis/erc20Abi.json'
import { formatTokenPrice } from 'utils/price'

export const placeOrder = async (data: OrderRequest) => {
  const res = await axios.post<OrderRequestResponse>('/order-place', data)
  return res.data
}

export const checkOrder = async (tx: string) => {
  const res = await axios.post('/order-check', {
    transactionId: tx
  })

  return res.data
}

export const createOrder = async ({ product, msg, sig }: { product: ProductMetadata } & SignatureRequest) => {
  return axios.post('/order-create', product, {
    headers: {
      msg,
      sig
    }
  })
}

export const checkP2POrder = async (tx: string) => {
  const res = await axios.post('/p2p-checkOrder', {
    transactionId: tx
  })

  return res.data
}

export const placeP2POrder = async (id: string) => {
  const res = await axios.post(`/p2p-placeOrder?id=${id}`)

  return res.data
}

export const createP2POrder = async ({
  orderId,
  order,
  msg,
  sig
}: { orderId: string; order: NFTOrder } & SignatureRequest) => {
  return axios.post(
    `/v2/orders/${orderId}/p2p`,
    {
      order
    },
    {
      headers: {
        msg: JSON.stringify(msg),
        sig
      }
    }
  )
}

interface SellBunnyData {
  name: string
  description: string
  price: number
  author_name: string
  rarity: string
}

export const createBunnyOrder = async ({
  bunnyData,
  order,
  msg,
  sig
}: { bunnyData: SellBunnyData; order: NFTOrder } & SignatureRequest) => {
  return axios.post(
    `/v2/orders/bunny`,
    {
      order_type: 2,
      ...bunnyData,
      order
    },
    {
      headers: {
        msg: JSON.stringify(msg),
        sig
      }
    }
  )
}

export const getOrdersOfSeller = async (account: string) => {
  const res = await axios.get<{
    cursor: number
    data: P2POrder[]
  }>(`/p2p-listOrdersOfSeller?sellerId=${account}`)

  return res.data
}

export const cancelOrder = async ({ orderId, sig, msg }: { orderId: string } & SignatureRequest) => {
  await axios.delete(`/v2/orders/p2p/${orderId}`, {
    headers: {
      msg: JSON.stringify(msg),
      sig
    }
  })
}

export const checkAuction = async (tx: string) => {
  const res = await axios.post('/auction-check', {
    transactionId: tx
  })

  return res.data
}

export const createAuctionOrder = async ({
  orderId,
  order,
  sig,
  msg
}: { orderId: string; order: NFTOrder } & SignatureRequest) => {
  return axios.post(
    `/v2/orders/${orderId}/auction`,
    {
      order
    },
    {
      headers: {
        msg: JSON.stringify(msg),
        sig
      }
    }
  )
}

export const getAuctionOrdersOfSeller = async (account: string) => {
  const res = await axios.get<{
    cursor: number
    data: P2POrder[]
  }>(`/auction-listOrdersOfSeller?sellerId=${account}`)

  return res.data
}

export const bidAuction = async ({
  orderId,
  order,
  sig,
  msg
}: { orderId: string; order: WyvernOrder } & SignatureRequest) => {
  return axios.post(
    `/v2/orders/auction/${orderId}/bids`,
    {
      order
    },
    {
      headers: {
        msg: JSON.stringify(msg),
        sig
      }
    }
  )
}

export const getBidsOfAuction = async ({
  id,
  msg,
  sig,
  limit,
  cursor
}: { id: string } & SignatureRequest & InfiniteQuery) => {
  const res = await axios.get(`/auction-listBidsOfOrder?orderId=${id}&limit=${limit}&cursor=${cursor}`, {
    headers: {
      msg,
      sig
    }
  })

  return res.data
}

export const getAuctionDetail = async ({ id, msg, sig }: { id: string } & SignatureRequest) => {
  const res = await axios.get(`/auction-getOrderDetails?orderId=${id}`, {
    headers: {
      msg,
      sig
    }
  })

  return res.data
}

export const cancelAuction = async ({ orderId, sig, msg }: { orderId: string } & SignatureRequest) => {
  await axios.delete(`/v2/orders/auction/${orderId}`, {
    headers: {
      msg: JSON.stringify(msg),
      sig
    }
  })
}

export const closeAuction = async ({ tx, id, msg, sig }: { tx: string; id: string } & SignatureRequest) => {
  return axios.put(
    `/v2/orders/bids/${id}`,
    {
      transaction_id: tx
    },
    {
      headers: {
        msg: JSON.stringify(msg),
        sig
      }
    }
  )
}

export const cancelFactoryOrder = async ({ orderId, sig, msg }: { orderId: string } & SignatureRequest) => {
  await axios.delete(`/v2/orders/${orderId}`, {
    headers: {
      msg: JSON.stringify(msg),
      sig
    }
  })
}

export const getBidsOfUser = async ({ msg, sig }: SignatureRequest) => {
  const res = await axios.get(`/auction-listBidOrdersOfUser`, {
    headers: {
      msg,
      sig
    }
  })

  return res.data
}

export const resolveOrder = async (type: OrderType, txHash: string) => {
  let endpoint = ''
  if (type === OrderType.FixedPrice) {
    endpoint = '/v2/orders/status'
  }
  if (type === OrderType.P2P) {
    endpoint = '/v2/orders/p2p/status'
  }
  if (type === OrderType.Auction) {
    endpoint = '/v2/orders/auction/status'
  }

  return axios.put(endpoint, {
    transaction_id: txHash
  })
}

export const getSignedOrder = async ({
  msg,
  sig,
  orderId,
  amount
}: { amount: number; orderId: string } & SignatureRequest) => {
  const data = await queryClient.getQueryData<any>(MANGA_ADDRESS + '/usd')
  const usdPrice = data?.[MANGA_ADDRESS]?.usd ? round(amount * data?.[MANGA_ADDRESS]?.usd, 2) : amount

  const res = await axios.get(`/v2/orders/signed-order/${orderId}?amount=${usdPrice}`, {
    headers: {
      msg: JSON.stringify(msg),
      sig
    }
  })

  return res.data
}

export const getKYCStatus = async (account: string) => {
  const res = await axios.get(`/v2/me/kyc?account=${account}`)

  return res.data
}

export const canCreateSellOrder = async (orderId: string) => {
  const res = await axios.get<{
    [OrderType.FixedPrice]: boolean
    [OrderType.P2P]: boolean
    [OrderType.Auction]: boolean
  }>(`/v2/orders/1opt?order_id=${orderId}`)

  return res.data
}

export const checkMemberShip = async (productId: string, account: string) => {
  if (!account) {
    return false
  }

  const membershipContract = new Contract(MEMBERSHIP_NFT_ADDRESS, ERC1155_ABI, networkProvider)
  const balanceOf = await membershipContract.balanceOf(account, MEMBERSHIP_LEVEL1_ID)

  return +balanceOf.toString() > 0
}

export const checkValidBalance = async (account: string | undefined, paymentToken: string, comparedPrice: number) => {
  if (!account) {
    return false
  }

  const tokenContract = new Contract(paymentToken, ERC20_ABI, networkProvider)
  const balanceOf = await tokenContract.balanceOf(account)

  return +formatTokenPrice(balanceOf.toString(), 18) > comparedPrice
}

export const mintOrder = async ({
  data,
  quantity,
  msg,
  sig
}: {
  data: {
    name: string
    description: string
    price: number
    author_name: string
    rarity: string
  }
  quantity: number
} & SignatureRequest) => {
  const res = await axios.post(`/v2/orders/selfserve?count=${quantity}`, data, {
    headers: {
      msg,
      sig
    }
  })
  return res.data
}

export const getImageUpload = async ({
  orderId,
  field,
  filename,
  msg,
  sig
}: { orderId: string; field: string; filename: string } & SignatureRequest) => {
  const res = await axios.get(`v2/assets/signed-url?order_id=${orderId}&field=${field}&filename=${filename}`, {
    headers: {
      msg,
      sig
    }
  })
  return res.data
}

export const uploadOrderImages = async ({ file, url }: { file: File; url: string }) => {
  const res = await axios.put(url, file, {
    headers: {
      'Content-Type': file.type
    }
  })
  return res
}

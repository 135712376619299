import React from 'react'
import { NavLink } from 'react-router-dom'
import { Trans } from '@lingui/macro'

export interface HomeMobileNavProps {
  onToggle: () => void
  open: boolean
}

const HomeMobileNav: React.FC<HomeMobileNavProps> = ({ open, onToggle }) => {
  return (
    <div
      className='w-full h-[calc(100vh-60px)] sm:h-[calc(100vh-80px)] py-[1.125rem] bg-white overflow-y-auto'
      style={{
        borderRight: '1px solid #E6E8EC'
      }}
    >
      <div className='flex justify-end pt-2 pb-4 px-6 border-b'>
        <img src='/images/marketplace/x_close.png' alt='close menu' className='w-4 h-4' onClick={onToggle} />
      </div>

      <nav className='p-6'>
        <ul className='flex flex-col menu-main font-medium'>
          <li className='menu-main-item mb-2'>
            <NavLink to='/manga-drop' activeClassName='border-b-2'>
              <Trans>MangaDrop</Trans>
            </NavLink>
          </li>

          {/* <li className='menu-main-item mb-2'>
            <NavLink to='/features' activeClassName='border-b-2'>
              <Trans>Features</Trans>
            </NavLink>
          </li> */}

          <li className='menu-main-item mb-2'>
            <NavLink exact to='/marketplace' activeClassName='border-b-2'>
              <Trans>Marketplace</Trans>
            </NavLink>
          </li>

          {/* <li className='menu-main-item mb-2'>
            <a href='https://farm.trophee.xyz'>
              <Trans>Farms</Trans>
            </a>
          </li> */}

          <li className='menu-main-item'>
            {/* <NavLink to='/faq' activeClassName='border-b-2'>
              <Trans>FAQ</Trans>
            </NavLink> */}
            <span className='opacity-50'>
              <Trans>FAQ</Trans>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default HomeMobileNav

import React, { useState, useMemo, useCallback, ChangeEvent } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Network, OpenSeaPort } from 'opensea-js'
import { t, Trans } from '@lingui/macro'
import debounce from 'lodash.debounce'

import { useInfiniteCollections, useSignDynamicMessage } from 'hooks'

import { Input, Loader, Collection, Select, Switch, ListWrapper } from 'components'

import { NFTOrder, OrderType } from 'types/order'
import { cancelAuction, cancelOrder, cancelFactoryOrder } from 'apis/order'
import { isTestNet, serialMapping } from 'utils/core'
import { cancelSellItemKey } from 'config/constantKey'
import { toastError, toastSuccess } from 'utils/toast'
import { ToastError } from 'components/ToastMessage'
import { getDetail } from 'queries/order'

type Option = {
  value: string
  label: string
}

export interface OnSaleProps {
  account?: string | null
}

const OnSale: React.FC<OnSaleProps> = ({ account }) => {
  // const fiterOptions: Option[] = [
  //   {
  //     label: t`Rarity`,
  //     value: 'rarity'
  //   },
  //   {
  //     label: t`Time Acquired`,
  //     value: 'time-acquired'
  //   },
  //   {
  //     label: t`Mangaka`,
  //     value: 'mangaka'
  //   }
  // ]

  const typeOptions: Option[] = [
    {
      label: t`All`,
      value: 'all'
    },
    {
      label: t`Fixed Price`,
      value: 'fixed'
    },
    {
      label: t`Auction`,
      value: 'auction'
    }
  ]

  const { account: currentAccount, library } = useWeb3React()
  const [search, setSearch] = useState<string>()
  // const [filter, setFilter] = useState<Option | null>(fiterOptions[0])
  const [type, setType] = useState<Option | null>(typeOptions[0])
  const [show, setShow] = useState<boolean>(false)
  const [canceling, setCanceling] = useState('')

  const isMyCollection = useMemo(() => account === currentAccount?.toLowerCase(), [account, currentAccount])

  const requestSign = useSignDynamicMessage(cancelSellItemKey)

  const { showOrders, isLoading, isEnd, ref, hideRefElement } = useInfiniteCollections(account, {
    seller_id: account?.toLowerCase(),
    status: 0,
    ...(!!search ? { _search: search } : {}),
    ...(type?.value === 'all' ? {} : { type: type?.value === 'fixed' ? 2 : 3 })
  })

  const handleSearch = useMemo(
    () =>
      debounce((val: ChangeEvent<HTMLInputElement>) => {
        setSearch(val.target.value?.toLowerCase())
      }, 300),
    []
  )
  const handleCancelSell = useCallback(
    (item: NFTOrder) => async () => {
      if (!isMyCollection) {
        return
      }

      try {
        const seaport = new OpenSeaPort(library.provider, {
          networkName: isTestNet ? Network.Ropsten : Network.Ethereum
        })

        const [sig, msg] = await requestSign({
          name: item.name,
          serial: `#${serialMapping(item)}`,
          price: (item.type === OrderType.Auction ? item.bid_highest?.price || item.price_from : item.price) || 0
        })

        setCanceling(item.id)
        const detailUrl = item.type === OrderType.Auction ? `/v2/orders/auction/${item.id}` : `/v2/orders/${item.id}`
        const detailWithSign = await getDetail(detailUrl, {
          msg,
          sig
        })

        if (detailWithSign.order.metadata.asset.id === '0') {
          await cancelFactoryOrder({
            orderId: item.id,
            sig,
            msg
          })
        } else {
          await seaport.cancelOrder({
            order: detailWithSign.order as any,
            accountAddress: account?.toLowerCase() || ''
          })

          item.type === OrderType.Auction
            ? await cancelAuction({
                orderId: item.id,
                sig,
                msg
              })
            : await cancelOrder({
                orderId: item.id,
                sig,
                msg
              })
        }

        toastSuccess(
          <div>
            Success!
            <p className='mt-1'>Your sell has been canceled!</p>
          </div>
        )

        setTimeout(() => window.location.reload(), 2000)
      } catch (error: any) {
        const err = error?.message ? error : new Error(error)
        toastError(<ToastError header='Failed!' message={error?.message || err?.message} />)
      } finally {
        setCanceling('')
      }
    },
    [account, library, requestSign, isMyCollection]
  )

  return (
    <>
      <div className='flex flex-wrap items-center'>
        <div className='flex-1 md:mr-[1rem] md:mb-0 mb-[1rem]'>
          <Input
            leftElement={
              <svg className='fill-current text-ink-lighter w-5 h-5'>
                <use xlinkHref='#svg-search'></use>
              </svg>
            }
            placeholder={t`Search title, mangaka or set...`}
            type='search'
            onChange={handleSearch}
          />
        </div>
        <div className='md:w-[170px] w-full md:mr-[1rem] md:mb-0 mb-[1rem]'>
          <Select options={typeOptions} defaultValue={typeOptions[0]} onChange={setType} />
        </div>
        {/* <div className='md:w-[170px] w-full md:mr-[1rem] md:mb-0 mb-[1rem]'>
          <Select options={fiterOptions} defaultValue={fiterOptions[0]} onChange={setFilter} />
        </div> */}
        <div className='flex-1 flex items-center md:mt-[1rem] lg:mt-0'>
          <span className='mr-2'>
            <Trans>Show Owner’s Inscription:</Trans>
          </span>
          <Switch onChange={e => setShow(e.target.checked)} />
        </div>
      </div>
      <ListWrapper
        isLoading={isLoading && !showOrders.length}
        isNoData={!search && showOrders.length === 0}
        isNoResult={!!search && showOrders.length === 0}
      >
        {showOrders.map(order => (
          <Collection
            key={order.id}
            order={order}
            show={show}
            isSell={true}
            onCancelSell={isMyCollection ? handleCancelSell(order) : undefined}
            canceling={order.id === canceling}
          />
        ))}
      </ListWrapper>
      {!hideRefElement && !isEnd && showOrders.length > 0 && (
        <div ref={ref}>
          <Loader />
        </div>
      )}
    </>
  )
}

export default OnSale

import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { Product } from 'types/product'
import { useSignMessage } from 'hooks'
import { viewDetailSignatureKey } from 'config/constantKey'
import { useSignature } from 'store/user/hook'
import { getMintProducts } from 'apis/mint'

import useInvalidateUrl from './invalidate'

export const mintProductQueryKey = '/v2/me/products/mint'

export const useMintProducts = () => {
  const invalidate = useInvalidateUrl(mintProductQueryKey)

  const signMessage = useSignMessage(viewDetailSignatureKey)
  const [signature] = useSignature(viewDetailSignatureKey)

  useEffect(() => {
    const requestSign = async () => {
      if (!signature) {
        await signMessage()
      }
    }

    requestSign()
  }, [signature, signMessage])

  return {
    invalidate,
    ...useQuery<{
      data: Product[]
      cursor: number
    }>(
      mintProductQueryKey,
      async () =>
        getMintProducts({
          url: mintProductQueryKey,
          msg: viewDetailSignatureKey,
          sig: signature || ''
        }),
      {
        enabled: !!signature
      }
    )
  }
}

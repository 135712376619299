import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'

import { Button, ClassificationInfo, CoverImage } from 'components'
import { useSellItemPopup } from 'store/popup/hook'
import { Classification } from 'types/product'
import { NFTCollectionDetail, OrderType } from 'types/order'
import { canCreateSellOrder } from 'apis/order'
import { useLocale } from 'hooks'

export interface CollectibleProps {
  nft: NFTCollectionDetail
  show?: boolean
  onSell?: () => void
}

const Collectible = ({ nft, show, onSell }: CollectibleProps) => {
  const locale = useLocale()
  const [sellPopup] = useSellItemPopup()
  const [canSell, setCanSell] = useState(false)

  const order = useMemo(() => nft.associated_order, [nft])

  const id = useMemo(() => order.id, [order])
  const author = useMemo(() => (locale === 'en' ? order.author_name : order.author_name_jp), [locale, order])
  const title = useMemo(() => (locale === 'en' ? order.name : order.name_jp), [locale, order])
  const image = useMemo(() => nft.assets_collections_urls?.marketplace_preview?.[0] || nft.assets_urls?.[1], [nft])
  const classification = useMemo(() => nft.rarity, [nft])
  const inscription = useMemo(() => order.inscription || nft.description, [order, nft])
  const type = useMemo(() => order.type, [order])

  const history = useHistory()

  useEffect(() => {
    const checkCanCreateSell = async (id: string) => {
      const data = await canCreateSellOrder(id)
      if (data[OrderType.P2P] && data[OrderType.Auction]) {
        setCanSell(true)
        return
      }

      setCanSell(false)
      return
    }
    // have on sell method
    if (onSell) {
      // check can sell more
      checkCanCreateSell(order.id as string)
    }
  }, [order.id, onSell, sellPopup?.visible])

  const handleClick = () => {
    if (type !== OrderType.Auction) {
      return history.push(`/orders/${id}`)
    }

    return history.push(`/auctions/${id}`)
  }

  return (
    <div
      className='rounded bg-white cursor-pointer border border-sky-light relative popup-collection-trigger'
      onClick={handleClick}
    >
      <CoverImage className='h-[302px]' imageUrl={image || ''} />
      <div className='p-3'>
        <p className='text-xs text-ink-lighter'>{author}</p>
        <p className='font-medium py-[0.375rem]'>{title}</p>
        {show && (
          <div className='rounded bg-sky-lightest py-2 px-3 mb-2'>
            <p className='text-xs text-ink-lighter overflow-hidden whitespace-nowrap overflow-ellipsis'>
              {inscription}
            </p>
          </div>
        )}
        <div className='flex justify-between items-center'>
          <span className='text-xs'>
            <ClassificationInfo classification={classification as Classification} />
          </span>
          <p className='text-xs text-ink-lighter font-medium'>
            <Trans>Edition</Trans>: {order.edition_number}
          </p>
        </div>
        {onSell ? (
          canSell ? (
            <Button
              className='mt-3 w-full popup-sell-item-trigger'
              onClick={e => {
                e.stopPropagation()
                onSell()
              }}
            >
              Sell
            </Button>
          ) : (
            <Button className='mt-3 w-full popup-sell-item-trigger' disabled>
              On Sale
            </Button>
          )
        ) : null}
      </div>
    </div>
  )
}

export default Collectible

import React, { useMemo } from 'react'

import { Detail, Metadata } from 'types/bunny'
import CoverImage from 'components/CoverImage'
import Button from 'components/Button'
import ClassificationInfo from 'components/ClassificationInfo'
import { useCommonPopup } from 'store/popup/hook'
import SellBunnyForm from 'components/MyCollection/SellBunnyForm'

export interface BunnyCardProps extends Detail {
  isMyBunny: boolean
}

const BunnyCard: React.FC<BunnyCardProps> = ({ isMyBunny, ...detail }) => {
  const { popup, setContent, setContainerClass } = useCommonPopup()
  const { name, image, attributes } = detail

  const handleSellItem = () => {
    setContent(<SellBunnyForm data={detail} onComplete={popup?.hide} />)
    setContainerClass('md:!w-[32rem] md:!ml-[-240px]')
    popup?.show()
  }

  const metadata = useMemo<Metadata | undefined>(() => {
    if (attributes?.length) {
      return attributes.reduce<Metadata>((acc, cur) => {
        return {
          ...acc,
          [cur.trait_type]: cur.value
        }
      }, {} as Metadata)
    }
  }, [attributes])

  return (
    <div className='rounded bg-white cursor-pointer border border-sky-light relative popup-collection-trigger'>
      <CoverImage className='h-[302px]' imageUrl={image || ''} />
      <div className='p-3'>
        <p className='text-xs text-ink-lighter'>
          Suicidal Rate : <span className='font-semibold'>{metadata?.['Suicidal Rate']}</span>
        </p>
        <p className='font-medium py-[0.375rem]'>{name}</p>

        <div className='flex justify-between items-center'>
          <span className='text-xs'>
            <ClassificationInfo rarity={metadata?.['Rarity']} />
          </span>
        </div>

        {isMyBunny && (
          <Button
            className='mt-3 w-full popup-sell-item-trigger'
            onClick={e => {
              e.stopPropagation()
              handleSellItem()
            }}
          >
            Sell
          </Button>
        )}
      </div>
    </div>
  )
}

export default BunnyCard

import React from 'react'
import { useWeb3React } from '@web3-react/core'

import { useInfiniteBridgeOrder } from 'hooks'

import { ReceiveItem, ListWrapper, Loader } from 'components'

const List = () => {
  const { account } = useWeb3React()
  const { showOrders, isLoading, isEnd, ref, hideRefElement } = useInfiniteBridgeOrder({
    owner_id: account?.toLowerCase()
  })

  return (
    <div className='py-20'>
      <ListWrapper isLoading={isLoading && !showOrders.length} isNoData={showOrders.length === 0}>
        {showOrders.map(order => (
          <ReceiveItem key={order.id} detail={order} />
        ))}
      </ListWrapper>
      {!hideRefElement && !isEnd && showOrders.length > 0 && (
        <div ref={ref}>
          <Loader />
        </div>
      )}
    </div>
  )
}

export default List

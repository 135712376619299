import React, { useEffect, useState } from 'react'
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { FieldErrors } from 'react-hook-form'
import classnames from 'classnames'
import draftToHtml from 'draftjs-to-html'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

type WYSIWYGEditorProps = {
  value: string
  errors?: FieldErrors
  name?: string
  updated?: boolean
  onChange: (value: string) => void
  setUpdated?: (update: boolean) => void
}

const WYSIWYGEditor = ({ name, value, errors, updated, setUpdated, onChange }: WYSIWYGEditorProps) => {
  const error = name && errors?.[name]?.message

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const onEditorStateChange = (editorState: EditorState) => {
    setUpdated?.(true)
    setEditorState(editorState)
    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  useEffect(() => {
    if (typeof updated !== 'undefined' && !updated) {
      const defaultValue = value ? value : ''
      const blocksFromHtml = convertFromHTML(defaultValue)
      const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap)
      const newEditorState = EditorState.createWithContent(contentState)
      setEditorState(newEditorState)
    }
  }, [updated, value])

  return (
    <>
      <div className={classnames('editor border border-sky-light rounded', { 'border-red-500': !!error })}>
        <Editor editorState={editorState} onEditorStateChange={onEditorStateChange} />
      </div>
      {!!error && <p className='text-red-500 mt-2'>{error}</p>}
    </>
  )
}

export default WYSIWYGEditor

import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'

import { Button } from 'components'
import { ToastSuccess } from 'components/ToastMessage'
import { useContract } from 'hooks'
import { NFTOrder } from 'types/order'

import { toastSuccess } from 'utils/toast'
import ERC1155_ABI from 'config/abis/erc1155Abi.json'

export interface TransferProps {
  order: NFTOrder | undefined
}

const Transfer: React.FC<TransferProps> = ({ order }) => {
  const { account } = useWeb3React()
  const contract = useContract(order?.order?.metadata?.asset?.address, ERC1155_ABI)

  const [loading, setLoading] = useState(false)

  const handleTransfer = async () => {
    try {
      setLoading(true)
      const desWallet = process.env.REACT_APP_AUTHOR_ADDRESS
      const tx = await contract?.safeTransferFrom(account, desWallet, order?.order?.metadata?.asset?.id, 1, '0x', {
        gasLimit: 100000
      })
      toastSuccess(<ToastSuccess header='Transfer Success with tx' message={tx.hash} tx={tx.hash} />)
    } catch (error: any) {
      console.error('transfer error', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button disabled={!order || !contract || loading} onClick={handleTransfer}>
      <Trans>Transfer Asset</Trans>
    </Button>
  )
}

export default Transfer

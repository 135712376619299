import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'

import Transfer from './Transfer'
import { BridgeItem, ConnectWalletButton, ListWrapper, Loader } from 'components'
import { useInfiniteCollections } from 'hooks'
import { NFTOrder } from 'types/order'

const List = () => {
  const [selectedItem, setSelectedItem] = useState<NFTOrder>()
  const { account } = useWeb3React()
  const { showOrders, isLoading, isEnd, ref, hideRefElement } = useInfiniteCollections(account, {
    owner_id: account?.toLowerCase(),
    status: 1
  })

  return (
    <div className='py-20'>
      <div className='border border-sky-light rounded shadow-xl'>
        <div className='p-4 border-[0px] border-b-[1px] border-sky-light border-solid'>
          <h4>
            <Trans>Bridge Asset from BSC to Ethereum</Trans>
          </h4>
        </div>
        <div className='p-4'>
          <div className='text-right'>
            {account ? (
              <Transfer order={selectedItem} />
            ) : (
              <div className='flex w-[fit-content] my-0 ml-auto'>
                <ConnectWalletButton />
              </div>
            )}
          </div>
          <ListWrapper isLoading={isLoading && !showOrders.length} isNoData={showOrders.length === 0}>
            {showOrders.map(order => (
              <BridgeItem
                key={order.id}
                order={order}
                isSelected={selectedItem?.id === order.id}
                setSelectedItem={setSelectedItem}
              />
            ))}
          </ListWrapper>
          {!hideRefElement && !isEnd && showOrders.length > 0 && (
            <div ref={ref}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default List

import { MEMBERSHIP_PRODUCT_ID, TROPHEE_OWNER_ADDRESS } from 'config/constantKey'
import { NFTOrder } from 'types/order'
import { Pagination, Product } from 'types/product'
import { explorerUrl } from './metamask'

export const querySelector = (selector: any, callback: any) => {
  const el = document.querySelectorAll(selector)

  if (el.length) {
    callback(el)
  }
}

export const isTestNet = process.env.REACT_APP_CHAIN_ID === '3'

export const onwerMapping = (address: string) => {
  if (address.toLowerCase() === TROPHEE_OWNER_ADDRESS.toLowerCase()) {
    return 'Trophee MangaDrop'
  }

  return address
}

export const checkKYCError = (error: any) => {
  const { response } = error
  return response?.data?.code
}

export const isMembershipProduct = (product: Product) => {
  return product.id.toLowerCase() === MEMBERSHIP_PRODUCT_ID.toLowerCase()
}

export const serialMapping = (order: NFTOrder) => {
  if (order.product_id.toLowerCase() === MEMBERSHIP_PRODUCT_ID.toLowerCase()) {
    return 'N.A'
  }

  return order.edition_number
}

export const checkAuctionEnded = (order: NFTOrder) => {
  if (!order) {
    return true
  }

  // already sold
  if (order.status === 1) {
    return true
  }

  if (order.expired_at) {
    return new Date(order.expired_at).getTime() <= new Date().getTime()
  }
}

export const getInfiniteUrl = (url: string, { _limit, _cursor }: Pagination = {}) => {
  if (_limit) {
    if (url.includes('?')) {
      url = url + `&_limit=${_limit}`
    } else {
      url = url + `?_limit=${_limit}`
    }
  }

  if (_cursor) {
    if (url.includes('?')) {
      url = url + `&_cursor=${_cursor}`
    } else {
      url = url + `?_cursor=${_cursor}`
    }
  }

  return url
}

export const getAddressUrl = (address: string) => {
  return `${explorerUrl}/address/${address}`
}

export const getTxUrl = (tx: string) => {
  return `${explorerUrl}/tx/${tx}`
}

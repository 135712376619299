import React, { useState, useMemo, ChangeEvent, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import debounce from 'lodash.debounce'
import Fuse from 'fuse.js'

import { useCollectionImages } from 'store/myCollection/hook'
import { useMyNFTDetails } from 'queries/myCollection'
import { ListWrapper, BunnyCard } from 'components'
import { useMyBunnyDetails } from 'queries/bunny'

export interface FABProps {
  account?: string | null
}

const FAB: React.FC<FABProps> = ({ account }) => {
  const [search, setSearch] = useState<string>()
  const [, setCollectionImages] = useCollectionImages()
  const { account: currentAccount } = useWeb3React()
  const isMyBunny = useMemo(() => account === currentAccount?.toLowerCase(), [account, currentAccount])

  const { data: myBunnies, isLoading: loadingBunny, invalidate: invalidateMyBunnies } = useMyBunnyDetails(account || '')

  console.log('ahihi myBunnies', myBunnies)

  const { data: showNFTs, isLoading } = useMyNFTDetails(account || '')

  const fuse = useMemo(
    () =>
      showNFTs &&
      new Fuse(showNFTs, {
        keys: ['name', 'author_name']
      }),
    [showNFTs]
  )

  const handleSearch = useMemo(
    () =>
      debounce((val: ChangeEvent<HTMLInputElement>) => {
        setSearch(val.target.value?.toLowerCase())
      }, 300),
    []
  )

  const searchNFTs = useMemo(() => {
    if (search) {
      const values = fuse?.search(search)
      if (values?.length) {
        return values.map(value => value.item)
      }
    }

    return showNFTs
  }, [fuse, search, showNFTs])

  useEffect(() => {
    setCollectionImages()
  }, [account, setCollectionImages])

  useEffect(() => {
    if (showNFTs) {
      showNFTs.map(nft =>
        setCollectionImages(currentState => ({
          ...currentState,
          [nft.id]: nft.assets_collections_urls?.marketplace_preview?.[0]
        }))
      )
    }
  }, [showNFTs, setCollectionImages])

  return (
    <ListWrapper
      isLoading={loadingBunny}
      isNoData={!search && myBunnies?.length === 0}
      isNoResult={!!search && myBunnies?.length === 0}
    >
      {myBunnies?.map(bunny => (
        <BunnyCard key={bunny.name} {...bunny} isMyBunny={isMyBunny} />
      ))}
    </ListWrapper>
  )
}

export default FAB

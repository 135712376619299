import React, { useState, useMemo, ChangeEvent, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { t, Trans } from '@lingui/macro'
import debounce from 'lodash.debounce'
import Fuse from 'fuse.js'

import { NFTOrder } from 'types/order'
import { useSellItemPopup } from 'store/popup/hook'
import { useCollectionImages, useSellItem } from 'store/myCollection/hook'
import { useMyNFTDetails } from 'queries/myCollection'
import { Collectible, Input, Switch, ListWrapper } from 'components'
import FadeAwayBunny from './FadeAwayBunny'

// type Option = {
//   value: string
//   label: string
// }

export interface CollectiblesProps {
  account?: string | null
}

const Collectibles: React.FC<CollectiblesProps> = ({ account }) => {
  // const options: Option[] = [
  //   {
  //     label: t`Rarity`,
  //     value: 'rarity'
  //   },
  //   {
  //     label: t`Time Acquired`,
  //     value: 'time-acquired'
  //   },
  //   {
  //     label: t`Mangaka`,
  //     value: 'mangaka'
  //   }
  // ]
  // const [filter, setFilter] = useState<Option | null>(options[0])
  const [search, setSearch] = useState<string>()
  const [show, setShow] = useState<boolean>(false)
  const [sellPopup] = useSellItemPopup()
  const [, setSellItem] = useSellItem()
  const [, setCollectionImages] = useCollectionImages()
  const { account: currentAccount } = useWeb3React()
  const isMyCollection = useMemo(() => account === currentAccount?.toLowerCase(), [account, currentAccount])

  const { data: showNFTs, isLoading } = useMyNFTDetails(account || '')

  const fuse = useMemo(
    () =>
      showNFTs &&
      new Fuse(showNFTs, {
        keys: ['name', 'author_name']
      }),
    [showNFTs]
  )

  const handleSearch = useMemo(
    () =>
      debounce((val: ChangeEvent<HTMLInputElement>) => {
        setSearch(val.target.value?.toLowerCase())
      }, 300),
    []
  )

  const searchNFTs = useMemo(() => {
    if (search) {
      const values = fuse?.search(search)
      if (values?.length) {
        return values.map(value => value.item)
      }
    }

    return showNFTs
  }, [fuse, search, showNFTs])

  const handleSellItem = (item: NFTOrder) => () => {
    if (!isMyCollection) {
      return
    }

    sellPopup?.show()
    setSellItem(item)
  }

  useEffect(() => {
    setCollectionImages()
  }, [account, setCollectionImages])

  useEffect(() => {
    if (showNFTs) {
      showNFTs.map(nft =>
        setCollectionImages(currentState => ({
          ...currentState,
          [nft.id]: nft.assets_collections_urls?.marketplace_preview?.[0]
        }))
      )
    }
  }, [showNFTs, setCollectionImages])

  return (
    <>
      <div>
        <h3 className='mb-3 text-xl'>Trophee Collection</h3>
        <div className='flex flex-wrap items-center'>
          <div className='flex-1 md:mr-[1rem] md:mb-0 mb-[1rem]'>
            <Input
              leftElement={
                <svg className='fill-current text-ink-lighter w-5 h-5'>
                  <use xlinkHref='#svg-search'></use>
                </svg>
              }
              placeholder={t`Search title, mangaka or set...`}
              type='search'
              onChange={handleSearch}
            />
          </div>
          {/* <div className='md:w-[170px] w-full md:mr-[1rem] md:mb-0 mb-[1rem]'>
          <Select options={options} defaultValue={options[0]} onChange={setFilter} />
        </div> */}
          <div className='flex-1 flex items-center md:mt-[1rem] lg:mt-0'>
            <span className='mr-2'>
              <Trans>Show Owner’s Inscription:</Trans>
            </span>
            <Switch onChange={e => setShow(e.target.checked)} />
          </div>
        </div>
        <ListWrapper
          isLoading={isLoading}
          isNoData={!search && searchNFTs?.length === 0}
          isNoResult={!!search && searchNFTs?.length === 0}
        >
          {searchNFTs?.map(nft => (
            <Collectible
              key={nft.id}
              nft={nft}
              onSell={isMyCollection ? handleSellItem(nft.associated_order as NFTOrder) : undefined}
              show={show}
            />
          ))}
        </ListWrapper>
      </div>
      <div>
        <h3 className='mt-4 text-xl'>Fade Away Bunny Collection</h3>
        <FadeAwayBunny account={account} />
      </div>
    </>
  )
}

export default Collectibles

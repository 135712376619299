export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect'
}

export interface WalletConfig {
  title: string
  icon: React.FC
  connectorId: ConnectorNames
}

import React from 'react'
import bannerImageUrl from 'assets/images/bg_header_purple.jpg'

export interface BannerProps {
  title: string
  description?: string
}

const Banner = ({ title, description }: BannerProps) => {
  return (
    <div
      style={{ backgroundImage: `url(${bannerImageUrl})` }}
      className='full-width md:-mt-4 -mt-9 text-center py-5 bg-cover'
    >
      <p className='text-white text-6xl'>{title}</p>
      {description && <p className='text-white text-xl mt-2'>{description}</p>}
    </div>
  )
}

export default Banner

export const storageKey = {
  connector: '@trophee/connector',
  chainId: '@trophee/chainId',
  language: '@trophee/language',
  sigMessage: 'Sign to authorize your self'
}

export const signatureKey = 'Please sign to connect to Trophee'
export const viewDetailSignatureKey = 'Please sign to authorize and able to view detail'
export const sellItemKey = 'Please confirm that you would like to sell:'
export const viewSellSignatureKey = 'Please sign to authorize and able to view your sell orders'
export const cancelSellItemKey = 'Confirm that you would like to cancel the sale of:'
export const bidItemKey = 'Please sign to authorize and able to bid this auction'
export const viewBidItemKey = 'Please sign to authorize and able to see all of bids of this auction'
export const approveBidKey = 'Please sign to authorize and able to approve this bid for your auction'
export const checkKYCKey = 'Please sign to authorize your KYC status and confirm that you would like to pay for:'
export const checkEligibilityKey = 'Please sign to check your KYC status'
export const linkWalletKey = 'Please sign to link your wallet address with your Trophee account'

export const SHOW_LIMIT = 20
export const MANGA_ADDRESS = process.env.REACT_APP_PAYMENT_TOKEN || ''
export const MAX_RESERVE_PRICE = 999999999999

export const TROPHEE_OWNER_ADDRESS =
  process.env.REACT_APP_AUTHOR_ADDRESS || '0xccd6cc00981952ca24e3ed61fe84b784f9cec9da'
export const MEMBERSHIP_NFT_ADDRESS =
  process.env.REACT_APP_CHAIN_ID === '1'
    ? '0xec793D33749c412AbfBbC5b2921ac7b2EE4aBe9c'
    : '0x355f98d7a683e243375f7ed82d5a2038535ee873'
export const MEMBERSHIP_LEVEL1_ID = '1'

export const MEMBERSHIP_PRODUCT_ID =
  process.env.REACT_APP_CHAIN_ID === '1' ? '77a3f553666ab77020a502e5079335c8' : '77a3f553666ab77020a502e5079335c8'
export const KYC_CODE = {
  Requested: 'KYC_REQUIRED',
  Pending: 'KYC_PENDING',
  Rejected: 'KYC_REJECTED'
}
export const KYC_LINK =
  'https://verify-with.blockpass.org/?clientId=trophee_digital_pte_ltd_8c5c9&serviceName=Trophee%20Digital%20Pte%20Ltd&env=prod'
export const notEnoughBalanceMessage = 'execution reverted: BEP20: transfer amount exceeds balance'

export const ACCESS_TOKEN_KEY = 'trophee-token'
export const TEMP_PASSWORD = 'trophee'
export const SIGN_UP_EMAIL = 'sign-up-email'
export const SIGN_UP_PHONE = 'sign-up-phonenumber'

export const nftAddress = process.env.REACT_APP_NFT_CONTRACT || ''
export const paymentTokenAddress = process.env.REACT_APP_PAYMENT_TOKEN || ''
export const bunnyNFTAddress = process.env.REACT_APP_BUNNY_CONTRACT || ''
export const bunnyMetadataUrl = process.env.REACT_APP_BUNNY_METADATA_URL || ''

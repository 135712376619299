import React, { useMemo } from 'react'

import { Classification } from 'types/product'
import { classificationNameMapping } from 'config/classification'
import { Rarity } from 'types/bunny'

export interface ClassificationInfoProps {
  classification?: Classification
  rarity?: Rarity
}

const ClassificationInfo: React.FC<ClassificationInfoProps> = ({ classification, rarity }) => {
  const theme = useMemo(() => {
    if (classification === Classification.Common || rarity === Rarity.Uncommon || rarity === Rarity.Common) {
      return 'text-semantic-common bg-semantic-commonBg'
    }

    if (classification === Classification.Rare || rarity === Rarity.Rare) {
      return 'text-semantic-blue bg-semantic-blueBg'
    }

    if (classification === Classification.Epic || rarity === Rarity.Mythical) {
      return 'text-semantic-purple bg-semantic-purpleBg'
    }

    if (classification === Classification.Unique || rarity === Rarity.Legendary) {
      return 'text-semantic-yellow bg-semantic-yellowBg'
    }

    if (classification === Classification.Special) {
      return 'text-semantic-green bg-semantic-greenBg'
    }
  }, [classification, rarity])

  return (
    <span className={`py-1 px-2 rounded ${theme}`}>
      {rarity ? rarity : classification ? classificationNameMapping[classification] : classification}
    </span>
  )
}

export default ClassificationInfo

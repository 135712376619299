import React, { useCallback, useMemo } from 'react'
import { t, Trans } from '@lingui/macro'

import { classificationColorMapping, classificationNameMapping } from 'config/classification'
import { Classification } from 'types/product'
import { useMyNFTDetails, useMyTotalNFTs } from 'queries/myCollection'
import { Loader } from 'components'

export interface CollectionHeaderInfoProps {
  account?: string | null
}

const Info: React.FC<CollectionHeaderInfoProps> = ({ account }) => {
  const { data: showOrders, isLoading } = useMyNFTDetails(account || '')
  const { data: myTotalNFTs } = useMyTotalNFTs(account || '')

  const getTotalByRarity = useCallback(
    (rarity: Classification) => {
      if (showOrders) {
        const byRarity = showOrders.filter(order => order.rarity === rarity)

        return byRarity.length
      }

      return 0
    },
    [showOrders]
  )

  const total = useMemo(
    () => [
      {
        title: t`Total Trophee collection size`,
        value: myTotalNFTs ?? 0
      }
    ],
    [myTotalNFTs]
  )
  const classifications = useMemo(
    () => [
      {
        type: Classification.Unique,
        value: getTotalByRarity(Classification.Unique)
      },
      {
        type: Classification.Epic,
        value: getTotalByRarity(Classification.Epic)
      },
      {
        type: Classification.Rare,
        value: getTotalByRarity(Classification.Rare)
      },
      {
        type: Classification.Special,
        value: 0
      },
      {
        type: Classification.Common,
        value: getTotalByRarity(Classification.Common)
      }
    ],
    [getTotalByRarity]
  )

  // const date = 'Mar 15, 2021'
  const name = 'Trophee User'

  const handleShare = (type: 'facebook' | 'twitter') =>
    function (evt: React.SyntheticEvent) {
      evt.preventDefault()

      const url = window.location.href
      let result: string

      switch (type) {
        case 'twitter':
          result = `http://twitter.com/share?url=${url}&text=My Trophee Collection&via=manga_token`
          break
        case 'facebook':
          result = `http://www.facebook.com/sharer.php?u=${url}&t=My Trophee Collection`
          break
        default:
          result = url
      }

      const popup = (window as any).open(result, 'Trophee', 'height=420, width=550')

      ;(window as any).focus && popup.focus()
    }

  return (
    <div className='bg-white border border-sky-light rounded px-4 py-8 shadow-xl'>
      <div className='text-center'>
        <img src='/images/avatar/04.jpg' alt='avatar' className='rounded-full w-[110px] h-[110px]' />
        <h4 className='font-medium text-2xl mt-4'>
          <Trans>{name}’s Collection</Trans>
        </h4>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='py-4 border-b'>
            {total.map((item, index) => (
              <div key={index} className='flex justify-between mt-2'>
                <p className='font-medium text-sm text-ink-lighter'>{item.title}</p>
                <p className='font-medium text-sm'>{item.value}</p>
              </div>
            ))}
          </div>
          <div className='py-4'>
            {classifications.map(item => (
              <div key={item.type} className='flex justify-between mt-2'>
                <p
                  className='font-medium text-sm'
                  style={{
                    color: classificationColorMapping[item.type]
                  }}
                >
                  {<Trans id={classificationNameMapping[item.type]} />}
                </p>
                <p className='font-medium text-sm'>{item.value}</p>
              </div>
            ))}
          </div>
        </>
      )}
      <div className='flex justify-center'>
        <span className='px-3 cursor-pointer' onClick={handleShare('facebook')}>
          <svg className='fill-current text-ink-lighter hover:text-blue w-[20px] h-[20px]'>
            <use xlinkHref='#svg-facebook'></use>
          </svg>
        </span>
        <span className='px-3 cursor-pointer' onClick={handleShare('twitter')}>
          <svg className='fill-current text-ink-lighter w-[20px] h-[20px]'>
            <use xlinkHref='#svg-twitter'></use>
          </svg>
        </span>
        <span className='px-3 cursor-pointer' onClick={() => navigator.clipboard.writeText(window.location.href)}>
          <svg className='fill-current text-ink-lighter w-[20px] h-[20px]'>
            <use xlinkHref='#svg-hyperlink'></use>
          </svg>
        </span>
      </div>
      {/* <div className='text-center text-ink-lighter text-sm mt-4'>
        <Trans>Member since {date}</Trans>
      </div> */}
    </div>
  )
}

export default Info

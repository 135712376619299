import React, { useEffect, useMemo, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { t, Trans } from '@lingui/macro'
import { useMutation } from 'react-query'

import { Product } from 'types/product'
import { useLocale, useSignMessage } from 'hooks'
import { editMintProduct } from 'apis/mint'
import { signatureKey } from 'config/constantKey'

import { toastError } from 'utils/toast'

import { Input, WYSIWYGEditor, Button } from 'components'
import { ToastError } from 'components/ToastMessage'

type EditModalProps = {
  product: Product
  invalidate: () => Promise<void>
  popup: any
}

type FormData = {
  name: string
  description: string
}

const EditModal = ({ product, invalidate, popup }: EditModalProps) => {
  const locale = useLocale()
  const requestSign = useSignMessage(signatureKey)

  const [updated, setUpdated] = useState(false)

  const { control, formState, handleSubmit, setValue } = useForm<FormData>({
    mode: 'onChange'
  })
  const { errors } = formState

  const { mutate: handleEditProduct, isLoading: isEditing } = useMutation(editMintProduct)

  const name = useMemo(() => (locale === 'en' ? product.name : product.name_jp), [locale, product])
  const description = useMemo(() => (locale === 'en' ? product.description : product.description_jp), [locale, product])

  const handleEdit = async (data: { name: string; description: string }) => {
    const [sig, msg] = await requestSign()

    handleEditProduct(
      { productId: product.id, data, sig, msg },
      {
        onSuccess: () => {
          invalidate()
          popup.hide?.()
        },
        onError: (error: any) => {
          toastError(<ToastError header={t`Update Product Failed!`} message={error?.message} />)
        }
      }
    )
  }

  useEffect(() => {
    setValue('name', name)
    setValue('description', description)
  }, [description, name, setValue])

  useEffect(() => {
    setUpdated(false)
  }, [product])

  return (
    <div>
      <form onSubmit={handleSubmit(handleEdit)}>
        <div className='grid'>
          <div>
            <label className='font-bold mb-2'>
              <Trans>Name</Trans>
            </label>
            <Controller
              render={({ field }) => <Input placeholder={t`Enter Order Name`} {...field} errors={errors} />}
              control={control}
              name='name'
              rules={{
                required: t`Name is required`
              }}
            />
          </div>
          <div>
            <label className='font-bold mb-2'>
              <Trans>Description</Trans>
            </label>
            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <WYSIWYGEditor {...field} errors={errors} updated={updated} setUpdated={setUpdated} />
              )}
              rules={{
                required: t`Description is required`
              }}
            />
          </div>
        </div>
        <div className='text-right mt-4'>
          <Button type='submit' isLoading={isEditing}>
            <Trans>Save</Trans>
          </Button>
        </div>
      </form>
    </div>
  )
}

export default EditModal

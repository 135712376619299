import { useQuery, useQueryClient } from 'react-query'
import qs from 'query-string'
import { Contract } from 'ethers'
import axios from 'axios'

import { BidOrder, NFTCollectionDetail, NFTOrder } from 'types/order'
import { nftAddress } from 'config/constantKey'
import { networkProvider } from 'hooks/useContract'

import useInvalidateUrl from './invalidate'

import nftABI from 'config/abis/nftAbi.json'

const filterNonNull = (obj: Object) => {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => k === 'status' || v))
}

export const collectiblesQueryKey = (account: string) => `/v2/orders?owner_id=${account.toLowerCase()}&status=1`
export const onSellQueryKey = (account: string) => `/v2/orders?seller_id=${account.toLowerCase()}&status=0`

export const useCollections = (query: any) => {
  const url = `/v2/orders?${qs.stringify(filterNonNull(query))}`
  const invalidate = useInvalidateUrl(url)

  return {
    invalidate,
    ...useQuery<{
      data: NFTOrder[]
      cursor: number
    }>(url, {
      enabled: !!query.owner_id || !!query.seller_id
    })
  }
}

export const useMyAuctionBids = (auctionId: string, query: any) => {
  const url = query
    ? `/v2/orders/auction/${auctionId}/bids?${qs.stringify(filterNonNull(query))}`
    : `/v2/orders/auction/${auctionId}/bids`
  return useQuery<{
    data: BidOrder[]
    cursor: number
  }>(url)
}

// GET COLLECTION DETAIL
const staleTime = 2 * 60 * 1000 // 2 mins

const nftContract = new Contract(nftAddress, nftABI, networkProvider)

export const getNFTDetail = async (tokenId: number): Promise<NFTCollectionDetail> => {
  const res = await axios.get(`/v2/products/item?token_address=${nftAddress}&token_id=${tokenId}`)

  return res.data
}

export const getMyNFTs = async (account: string) => {
  if (account) {
    const balance = await nftContract.balanceOf(account)
    const total = +balance.toString() ?? 0
    const promises = []

    for (let i = 0; i < total; i++) {
      promises.push(nftContract.tokenOfOwnerByIndex(account, i))
    }

    const settled = await Promise.allSettled(promises)
    const myTokens = settled
      .map(item => {
        if (item.status === 'fulfilled') {
          return item.value
        }

        return undefined
      })
      .filter(x => x)
      .sort((a, b) => a - b)

    return myTokens
  }
}

export const myTotalNFTsQueryKey = (account: string) => `@trophee_total_nfts/${account}`
export const useMyTotalNFTs = (account: string) => {
  return useQuery<number>(
    myTotalNFTsQueryKey(account),
    async () => {
      const balance = await nftContract.balanceOf(account)

      return +balance.toString() ?? 0
    },
    {
      enabled: !!account,
      staleTime
    }
  )
}

export const getMyNFTDetails = async (account: string) => {
  const myNFTs = await getMyNFTs(account)
  if (myNFTs && myNFTs.length > 0) {
    const promises: Promise<NFTCollectionDetail>[] = []

    myNFTs.forEach(nft => promises.push(getNFTDetail(nft)))

    const settled = await Promise.allSettled(promises)
    const details = settled
      .map(item => {
        if (item.status === 'fulfilled') {
          return item.value
        }

        return undefined
      })
      .filter(x => x)

    if (!details?.length) {
      return []
    }

    return details.map((detail, index) => ({
      ...detail,
      tokenId: myNFTs[index].toString()
    }))
  }

  return []
}

export const myNFTDetailsQueryKey = (account: string) => `@trophee_nfts/${account}`
export const useMyNFTDetails = (account: string) => {
  const queryClient = useQueryClient()

  return {
    ...useQuery<NFTCollectionDetail[]>(myNFTDetailsQueryKey(account), () => getMyNFTDetails(account) as any, {
      enabled: !!account,
      retry: 0,
      staleTime
    }),
    invalidate: () => queryClient.invalidateQueries(myNFTDetailsQueryKey(account))
  }
}

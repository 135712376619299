import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faMedium, faDiscord, faYoutube, faTelegram, faInstagram } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  return (
    <div className='flex flex-wrap justify-between items-center border-[0px] border-t-[1px] border-sky-light border-solid md:px-8 py-6 px-3'>
      <div className='mx-auto sm:!mx-0'>
        <div className='logo ml-3 sm:ml-6'>
          <img src='/images/trophee-eth-footer-logo.svg' className='w-[148px] sm:w-[166px] h-12' alt='logo' />
        </div>
      </div>
      <div className='flex w-full lg:w-auto lg:flex-row flex-col items-center pt-[0.5rem] lg:pt-0'>
        <div className='px-3 pb-[1rem] lg:pb-0'>
          <Link to='/about-us'>
            <Trans>About Us</Trans>
          </Link>
        </div>
        <div className='px-3 pb-[1rem] lg:pb-0 opacity-50'>
          {/* <Link to='/faq'> */}
          <Trans>FAQ</Trans>
          {/* </Link> */}
        </div>
      </div>
      {/* <div className='hidden lg:block ml-10'>
        <SwitchLanguage mode='light' />
      </div> */}
      <div className='flex items-center justify-center w-full lg:w-auto pb-[1rem] lg:pb-0'>
        <a href='https://twitter.com/manga_token' target='_blank' className='px-3' rel='noreferrer'>
          <FontAwesomeIcon icon={faTwitter} className='text-ink-light w-[20px] h-[20px] hover:!text-purple' />
        </a>
        <a href='https://medium.com/manga-token' target='_blank' className='px-3' rel='noreferrer'>
          <FontAwesomeIcon icon={faMedium} className='text-ink-light w-[26px] h-[20px] hover:!text-purple' />
        </a>
        <a href='https://discord.gg/mangatoken' target='_blank' className='px-3' rel='noreferrer'>
          <FontAwesomeIcon icon={faDiscord} className='text-ink-light w-[26px] h-[20px] hover:!text-purple' />
        </a>
        <a href='https://www.youtube.com/c/mangatoken' target='_blank' className='px-3' rel='noreferrer'>
          <FontAwesomeIcon icon={faYoutube} className='text-ink-light w-[26px] h-[20px] hover:!text-purple' />
        </a>
        <a href='https://t.me/mangatokenofficial' target='_blank' className='px-3' rel='noreferrer'>
          <FontAwesomeIcon icon={faTelegram} className='text-ink-light w-[20px] h-[20px] hover:!text-purple' />
        </a>
        <a href='https://www.instagram.com/manga_token' target='_blank' className='px-3' rel='noreferrer'>
          <FontAwesomeIcon icon={faInstagram} className='text-ink-light w-[26px] h-[20px] hover:!text-purple' />
        </a>
      </div>
      <div className='text-sm w-full lg:w-auto text-center'>
        <Trans>© 2021 Trophee. All rights reserved.</Trans>
      </div>
    </div>
  )
}

export default Footer

import React, { useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'

import { useInfiniteMintProduct, useSignMessage } from 'hooks'
import { getImageUpload, mintOrder, uploadOrderImages } from 'apis/order'
import { signatureKey } from 'config/constantKey'
import { toastError } from 'utils/toast'

import {
  Button,
  ConnectWalletButton,
  Input,
  InputFile,
  ListWrapper,
  Loader,
  MintItem,
  Select,
  WYSIWYGEditor
} from 'components'
import { ToastError } from 'components/ToastMessage'

type Option = {
  value: string
  label: string
}

type FormData = {
  name: string
  description: string
  price: number
  author: string
  rarity: Option
  quantity: number
  asset_nft?: FileList
  featured_collection?: FileList
  marketplace_preview?: FileList
  product_carousel?: FileList
  product_full_screen?: FileList
  video?: FileList
}

const dataRarity = [
  {
    label: 'Common',
    value: 'common'
  },
  {
    label: 'Rare',
    value: 'rare'
  },
  {
    label: 'Epic',
    value: 'epic'
  },
  {
    label: 'Unique',
    value: 'unique'
  },
  {
    label: 'Special',
    value: 'special'
  }
]

const List = () => {
  const { account } = useWeb3React()
  const requestSign = useSignMessage(signatureKey)

  const [isCreatingOrder, setIsCreatingOrder] = useState<boolean>(false)

  const { control, formState, handleSubmit, reset } = useForm<FormData>({
    mode: 'onChange'
  })

  const { errors } = formState

  const { showProducts, isLoading, isEnd, ref, hideRefElement, invalidate } = useInfiniteMintProduct()
  const { mutateAsync: handleCreateOrder } = useMutation(mintOrder)
  const { mutateAsync: handleGetUploadImage } = useMutation(getImageUpload)
  const { mutateAsync: handleUploadImages } = useMutation(uploadOrderImages)

  const getFormData = (values: FormData) => {
    let hasFile = false
    const filesData = []
    if (values.asset_nft) {
      hasFile = true
      filesData.push({
        field: 'asset_nft',
        file: values.asset_nft[0]
      })
    }
    if (values.featured_collection) {
      hasFile = true
      Array.from(values.featured_collection).map(file =>
        filesData.push({
          field: 'featured_collection',
          file: file
        })
      )
    }
    if (values.marketplace_preview) {
      hasFile = true
      Array.from(values.marketplace_preview).map(file =>
        filesData.push({
          field: 'marketplace_preview',
          file: file
        })
      )
    }
    if (values.product_carousel) {
      hasFile = true
      Array.from(values.product_carousel).map(file =>
        filesData.push({
          field: 'product_carousel',
          file: file
        })
      )
    }
    if (values.product_full_screen) {
      hasFile = true
      Array.from(values.product_full_screen).map(file =>
        filesData.push({
          field: 'product_full_screen',
          file: file
        })
      )
    }
    if (values.video) {
      hasFile = true
      Array.from(values.video).map(file =>
        filesData.push({
          field: 'video',
          file: file
        })
      )
    }
    return {
      hasFile,
      data: filesData
    }
  }

  const handleMint = async (values: FormData) => {
    const [sig, msg] = await requestSign()

    const dataCreateOrder = {
      name: values.name,
      description: values.description,
      price: values.price,
      author_name: values.author,
      rarity: values.rarity.value
    }
    const dataUpload = getFormData(values)
    setIsCreatingOrder(true)
    try {
      const res = await handleCreateOrder({ data: dataCreateOrder, quantity: values.quantity, msg, sig })
      if (res) {
        if (dataUpload.hasFile) {
          dataUpload.data.map(async dataUpload => {
            const uploadedFile = await handleGetUploadImage({
              orderId: res.orders?.[0],
              field: dataUpload.field,
              filename: dataUpload.file.name,
              msg,
              sig
            })
            await handleUploadImages({ file: dataUpload.file, url: uploadedFile.url })
            invalidate()
          })
        } else {
          invalidate()
        }
        reset()
      }
    } catch (error: any) {
      toastError(<ToastError header={t`Mint Order Failed!`} message={error?.message} />)
    } finally {
      setIsCreatingOrder(false)
    }
  }

  return (
    <div className='py-20'>
      <div className='border border-sky-light rounded shadow-xl'>
        <div className='p-4 border-[0px] border-b-[1px] border-sky-light border-solid'>
          <h4>
            <Trans>Mint Your NFT</Trans>
          </h4>
        </div>
        <div className='p-4'>
          <form onSubmit={handleSubmit(handleMint)}>
            <div className='mb-[1rem] grid grid-cols-1 md:grid-cols-2'>
              <div className='grid grid-cols-1'>
                <Controller
                  render={({ field }) => <Input placeholder={t`Enter Order Name`} {...field} errors={errors} />}
                  control={control}
                  name='name'
                  rules={{
                    required: t`Name is required`
                  }}
                />
                <Controller
                  name='description'
                  control={control}
                  render={({ field }) => <WYSIWYGEditor {...field} errors={errors} />}
                  rules={{
                    required: t`Description is required`
                  }}
                />
                <Controller
                  render={({ field }) => <Input placeholder={t`Enter Order Price`} {...field} errors={errors} />}
                  control={control}
                  name='price'
                  rules={{
                    required: t`Price is required`,
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: t`Invalid Price`
                    }
                  }}
                />
                <Controller
                  render={({ field }) => <Input placeholder={t`Enter Author`} {...field} errors={errors} />}
                  control={control}
                  name='author'
                  rules={{
                    required: t`Author is required`
                  }}
                />
                <Controller
                  control={control}
                  name='rarity'
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={dataRarity}
                      placeholder={t`Select Rarity`}
                      inputProps={{ autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off' }}
                      errors={errors}
                    />
                  )}
                  rules={{
                    required: t`Rarity is required`
                  }}
                />
                <Controller
                  render={({ field }) => <Input placeholder={t`Enter Quantity`} {...field} errors={errors} />}
                  control={control}
                  name='quantity'
                  rules={{
                    required: t`Quantity is required`,
                    pattern: {
                      value: /^(0|[1-9][0-9]*)$/,
                      message: t`Invalid Quantity`
                    }
                  }}
                />
              </div>
              <div className='grid grid-cols-1'>
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset NFT`} type='file' {...field} errors={errors} />
                  )}
                  control={control}
                  name='asset_nft'
                />
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset Featured Collection`} multiple {...field} errors={errors} />
                  )}
                  control={control}
                  name='featured_collection'
                />
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset Marketplace Preview`} multiple {...field} errors={errors} />
                  )}
                  control={control}
                  name='marketplace_preview'
                />
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset Product Carousel`} multiple {...field} errors={errors} />
                  )}
                  control={control}
                  name='product_carousel'
                />
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset Video`} accept='video/*' multiple {...field} errors={errors} />
                  )}
                  control={control}
                  name='video'
                />
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset Product Fullscreen`} multiple {...field} errors={errors} />
                  )}
                  control={control}
                  name='product_full_screen'
                />
              </div>
            </div>
            <div className='text-right'>
              {account ? (
                <Button type='submit' disabled={isCreatingOrder} isLoading={isCreatingOrder}>
                  <Trans>Mint</Trans>
                </Button>
              ) : (
                <div className='flex w-[fit-content] my-0 mx-auto'>
                  <ConnectWalletButton />
                </div>
              )}
            </div>
          </form>
          <ListWrapper isLoading={isLoading && !showProducts.length} isNoData={showProducts.length === 0}>
            {showProducts.map(product => (
              <MintItem key={product.id} product={product} invalidate={invalidate} />
            ))}
          </ListWrapper>
          {!hideRefElement && !isEnd && showProducts.length > 0 && (
            <div ref={ref}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default List

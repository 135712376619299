import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { round } from 'lodash'

import { Button, ClassificationInfo, CoverImage } from 'components'
import { useCollectionPopup, useSellItemPopup } from 'store/popup/hook'
import { useCollection } from 'store/myCollection/hook'
import { useUSDPrice } from 'hooks/useUSDPrice'
import { Classification } from 'types/product'
import { NFTOrder, OrderType } from 'types/order'
import { canCreateSellOrder } from 'apis/order'
import { useLocale } from 'hooks'

import { MANGA_ADDRESS } from 'config/constantKey'
import { formatCurrency } from 'utils/price'
import { serialMapping } from 'utils/core'

type CollectionProps = {
  order: NFTOrder
  quantity?: number
  show?: boolean
  canceling?: boolean
  isSell?: boolean
  onSell?: () => void
  onCancelSell?: () => void
}

const Collection = ({
  order,
  quantity = 1,
  show,
  canceling,
  isSell = false,
  onSell,
  onCancelSell
}: CollectionProps) => {
  const locale = useLocale()
  const [collectionPopup] = useCollectionPopup()
  const [, setCollection] = useCollection()
  const [sellPopup] = useSellItemPopup()
  const [canSell, setCanSell] = useState(false)

  console.log('ahihi order', order)

  const fixedPrice = useMemo(() => order.type !== OrderType.Auction && order.price, [order])
  const auctionPrice = useMemo(() => order?.bid_highest?.price || order?.price_from, [order])

  const usdPrice = useUSDPrice(fixedPrice || 0, '0xc2cb89bbb5bba6e21db1dfe13493dfd7dcbabd68')
  const usdCurrentBid = useUSDPrice(auctionPrice || 0, '0xc2cb89bbb5bba6e21db1dfe13493dfd7dcbabd68')

  const id = useMemo(() => order.id, [order])
  const author = useMemo(() => (locale === 'en' ? order.author_name : order.author_name_jp), [locale, order])
  const title = useMemo(() => (locale === 'en' ? order.name : order.name_jp), [locale, order])
  const image = useMemo(
    () => order.product.assets_collections_urls?.marketplace_preview?.[0] || order.product.assets_urls?.[1],
    [order]
  )
  const classification = useMemo(() => order.product.rarity, [order])
  const inscription = useMemo(() => order.inscription || order.product.description, [order])
  const type = useMemo(() => order.type, [order])

  const history = useHistory()

  useEffect(() => {
    const checkCanCreateSell = async (id: string) => {
      const data = await canCreateSellOrder(id)
      if (data[OrderType.P2P] && data[OrderType.Auction]) {
        setCanSell(true)
        return
      }

      setCanSell(false)
      return
    }
    // have on sell method
    if (onSell) {
      // check can sell more
      checkCanCreateSell(order.id as string)
    }
  }, [order.id, onSell, sellPopup?.visible])

  const handleClick = () => {
    if (quantity && quantity > 1 && !collectionPopup?.open) {
      collectionPopup?.show()
      setCollection({
        author,
        title,
        image,
        classification,
        inscription
      })
    }

    if (type !== OrderType.Auction) {
      return history.push(`/orders/${id}`)
    }

    return history.push(`/auctions/${id}`)
  }

  return (
    <div
      className='rounded bg-white cursor-pointer border border-sky-light relative popup-collection-trigger'
      onClick={handleClick}
    >
      <CoverImage className='h-[302px]' imageUrl={image || ''} />
      <div className='p-3'>
        <p className='text-xs text-ink-lighter'>{author}</p>
        <p className='font-medium py-[0.375rem]'>{title}</p>
        {show && (
          <div className='rounded bg-sky-lightest py-2 px-3 mb-2'>
            <p className='text-xs text-ink-lighter overflow-hidden whitespace-nowrap overflow-ellipsis'>
              {inscription}
            </p>
          </div>
        )}
        <div className='flex justify-between items-center'>
          <span className='text-xs'>
            <ClassificationInfo classification={classification as Classification} />
          </span>
          <p className='text-xs text-ink-lighter font-medium'>
            <Trans>Edition</Trans>: {serialMapping(order)}
          </p>

          {/* <p className='text-xs text-ink-lighter'>
            {quantity && quantity > 1 ? (
              <Trans>{quantity} Editions</Trans>
            ) : (
              <Trans>Edition: {edition}</Trans>
            )}
          </p> */}
        </div>
        {isSell && (
          <div className='mt-3 flex'>
            <div className='flex-1'>
              <p className='mb-[0.125rem] text-xs text-new-darker'>
                <Trans>Buy now</Trans>
              </p>
              <div className='flex items-center'>
                <img src={`/images/tokens/${MANGA_ADDRESS}.png`} alt='payment logo' className='w-5 h-5' />
                <p className='ml-[6px] font-medium'>{formatCurrency(round(fixedPrice || 0, 6))}</p>
              </div>
              <p className='mt-1 text-xs text-new-darker'>~${round(usdPrice, 6)}</p>
            </div>
            {type === OrderType.Auction && <div className='w-px bg-sky-light mx-2'></div>}
            {type === OrderType.Auction && (
              <div className='flex-1'>
                <p className='mb-[0.125rem] text-xs text-new-darker'>
                  <Trans>Bid</Trans>
                </p>

                <div className='flex items-center'>
                  <img src={`/images/tokens/${MANGA_ADDRESS}.png`} alt='payment logo' className='w-5 h-5' />
                  <p className='ml-[6px] font-medium'>{formatCurrency(round(auctionPrice || 0, 6))}</p>
                </div>
                <p className='ml-[2px] text-xs text-new-darker'>~${round(usdCurrentBid, 6)}</p>
              </div>
            )}
          </div>
        )}
        {onSell ? (
          canSell ? (
            <Button
              className='mt-3 w-full popup-sell-item-trigger'
              onClick={e => {
                e.stopPropagation()
                onSell()
              }}
            >
              Sell
            </Button>
          ) : (
            <Button className='mt-3 w-full popup-sell-item-trigger' disabled>
              On Sale
            </Button>
          )
        ) : null}
        {onCancelSell && (
          <Button
            className='mt-3 w-full bg-red-500 hover:bg-red-200'
            onClick={e => {
              e.stopPropagation()
              onCancelSell()
            }}
            isLoading={canceling}
          >
            {type === OrderType.Auction ? 'Cancel Auction' : 'Cancel Order'}
          </Button>
        )}
      </div>
      {quantity && quantity > 1 && (
        <>
          <div className='absolute w-full'>
            <div className='border border-sky-light h-[7px] rounded-b mx-2'></div>
          </div>
          <div className='absolute w-full bottom-[-13px]'>
            <div className='border border-sky-light rounded-b h-[7px] mx-3'></div>
          </div>
        </>
      )}
    </div>
  )
}

export default Collection

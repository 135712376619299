import React, { useRef } from 'react'
import { FieldErrors } from 'react-hook-form'
import classnames from 'classnames'

type InputFileProps = {
  accept?: string
  type?: string
  multiple?: boolean
  errors?: FieldErrors
  name?: string
  label?: string
  value?: FileList
  onChange: (file: FileList | null) => void
}

const InputFile = React.forwardRef<HTMLInputElement, InputFileProps>(
  (
    { type = 'file', accept = 'image/*', multiple = false, errors, name, label, onChange, ...props }: InputFileProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const error = name && errors?.[name]?.message
    return (
      <div>
        <div
          className={classnames(
            'flex flex-wrap items-center min-h-[54px] px-[16px] border border-sky-light rounded bg-white',
            {
              'border-red-500': !!error
            }
          )}
        >
          <input
            type={type}
            accept={accept}
            ref={inputRef}
            className='hidden'
            onChange={e => onChange(e?.target?.files || null)}
            multiple={multiple}
          />
          <div
            className='cursor-pointer border border-new-primary rounded px-2 text-new-primary'
            onClick={() => inputRef.current && inputRef.current.click()}
          >
            {label}
          </div>
          {props?.value && (
            <div className='ml-2 flex flex-wrap'>
              {Array.from(props.value).map((file, index) => {
                const length = props.value?.length || 0
                return (
                  <div className='flex' key={index}>
                    <p className='truncate max-w-[150px]'>{file.name}</p>
                    {index !== length - 1 && <span className='mr-2'>,</span>}
                  </div>
                )
              })}
            </div>
          )}
        </div>
        {!!error && <p className='text-red-500 mt-2'>{error}</p>}
      </div>
    )
  }
)

export default InputFile

import axios from 'axios'

import { SignatureRequest } from './signature'
import { InfiniteQuery } from 'types/product'

export const getBridgeOrders = async ({ url, msg, sig }: { url: string } & SignatureRequest & InfiniteQuery) => {
  const res = await axios.get(url, {
    headers: {
      msg,
      sig
    }
  })

  return res.data
}

import React, { useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { OpenSeaPort, Network } from 'opensea-js'
import { WyvernSchemaName } from 'opensea-js/lib/types'

import Button from '../Button'
import { Input } from 'components'
import { ToastError } from 'components/ToastMessage'
import { useSellItem } from 'store/myCollection/hook'
import { useSignDynamicMessage } from 'hooks'
import { collectiblesQueryKey, onSellQueryKey } from 'queries/myCollection'
import useInvalidateUrl from 'queries/invalidate'
import { createBunnyOrder } from 'apis/order'

import { isTestNet } from 'utils/core'
import { toastError, toastSuccess } from 'utils/toast'
import { sellItemKey, MANGA_ADDRESS, bunnyNFTAddress } from 'config/constantKey'
import { Detail, Metadata } from 'types/bunny'

export interface SellBunnyProps {
  data: Detail
  onComplete: (() => void) | undefined
}

const SellBunnyForm: React.FC<SellBunnyProps> = ({ data, onComplete }) => {
  const { account, library } = useWeb3React()
  const [sellItem] = useSellItem()
  const [price, setPrice] = useState<string>('0')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const metadata = useMemo<Metadata | undefined>(() => {
    if (data.attributes?.length) {
      return data.attributes.reduce<Metadata>((acc, cur) => {
        return {
          ...acc,
          [cur.trait_type]: cur.value
        }
      }, {} as Metadata)
    }
  }, [data])

  const invalidateCollectibleList = useInvalidateUrl(collectiblesQueryKey(account || ''))
  const invalidateOnSellList = useInvalidateUrl(onSellQueryKey(account || ''))

  const requestSign = useSignDynamicMessage(sellItemKey)

  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value || value.match(/^\d{1,}(\.\d{0,6})?$/)) {
      setPrice(value)
    }
  }

  const handleFixedPrice = async () => {
    if (account) {
      setError('')

      if (!price) {
        setError('Price should greater than 0')
        return
      }

      const seaport = new OpenSeaPort(library.provider, {
        networkName: isTestNet ? Network.Ropsten : Network.Ethereum
      })

      const [sig, msg] = await requestSign({
        name: data.name,
        serial: `#${data.tokenId}`,
        price: +price
      })

      try {
        setIsLoading(true)

        const order = await seaport.createSellOrder({
          asset: {
            tokenId: data.tokenId,
            tokenAddress: bunnyNFTAddress,
            schemaName: WyvernSchemaName.ERC721
          },
          quantity: 1,
          accountAddress: account,
          startAmount: +price,
          endAmount: +price,
          paymentTokenAddress: process.env.REACT_APP_PAYMENT_TOKEN || ''
        })

        await createBunnyOrder({
          bunnyData: {
            name: data.name,
            description: data.description,
            author_name: '',
            rarity: metadata?.['Rarity'] || '',
            price: +price
          },
          order: order as any,
          sig,
          msg
        })
        await Promise.all([invalidateCollectibleList(), invalidateOnSellList()])

        toastSuccess(
          <div>
            Success!
            <p className='mt-1'>Your order has been created!</p>
          </div>
        )
        onComplete?.()
      } catch (error: any) {
        const err = new Error(error)
        toastError(<ToastError header='Failed!' message={err?.message} />)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <div>
      <h4 className='mb-6 text-center'>Sell Your Bunny</h4>
      <div className='widget-box-content'>
        <form className='form'>
          <div className='form-row'>
            <div className='form-item'>
              <div className='form-input small active'>
                <label htmlFor='item-name'>Item Name</label>
                <Input type='text' id='item-name' name='item_name' value={data.name} disabled />
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='mb-3 form-item'>
              <div className='form-input small active'>
                <label htmlFor='price'>Price</label>
                <Input
                  leftElement={
                    <img
                      src={`/images/tokens/${sellItem?.order.paymentToken || MANGA_ADDRESS}.png`}
                      alt='payment logo'
                      className='w-5 h-5'
                    />
                  }
                  id='price'
                  name='price'
                  value={price}
                  onChange={handleChangePrice}
                />
              </div>
            </div>
          </div>

          {error && <p className='mb-3 text-red-500 font-bold text-center '>{error}</p>}

          <Button className='primary md:ml-[28px]' type='button' isLoading={isLoading} onClick={handleFixedPrice}>
            Submit
          </Button>
        </form>
      </div>
    </div>
  )
}

export default SellBunnyForm

import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import { OpenSeaPort, Network } from 'opensea-js'

import { KYC_CODE, notEnoughBalanceMessage } from 'config/constantKey'
import { checkKYCError, isTestNet } from 'utils/core'
import { toastError, toastSuccess } from 'utils/toast'
import { useCommonPopup, useKYCPopup } from 'store/popup/hook'
import { BridgeOrder } from 'types/order'

import { Button } from 'components'
import { ToastError, ToastSuccess } from 'components/ToastMessage'
import { InsufficientModal } from 'components/Modal'

type ItemProps = {
  detail: BridgeOrder
}

const Item = ({ detail }: ItemProps) => {
  const { account, library } = useWeb3React()
  const history = useHistory()

  const [receiving, setReceiving] = useState(false)

  const KYCPopup = useKYCPopup()
  const { popup: commonPopup, setContent } = useCommonPopup()

  const handleReceive = async () => {
    if (account) {
      setReceiving(true)
      try {
        const seaport = new OpenSeaPort(library.provider, {
          networkName: isTestNet ? Network.Ropsten : Network.Ethereum
        })

        const tx = await seaport.fulfillOrder({ order: detail.order as any, accountAddress: account })
        console.log('ahihi tx', tx)
        if (tx) {
          toastSuccess(<ToastSuccess message={tx} tx={tx} />)

          setTimeout(() => history.replace(`/collections/${account?.toLowerCase()}`), 2000)
        } else {
          toastError(<ToastError message='Cannot create transaction' />)
        }
      } catch (error: any) {
        const code = checkKYCError(error)
        if (code === KYC_CODE.Requested) {
          KYCPopup.Request?.show()
          return
        }
        if (code === KYC_CODE.Pending) {
          KYCPopup.Pending?.show()
          return
        }
        if (code === KYC_CODE.Rejected) {
          KYCPopup.Reject?.show()
          return
        }

        const err = error?.message ? error : new Error(error)
        if (err.message?.includes(notEnoughBalanceMessage)) {
          setContent(<InsufficientModal paymentToken={detail.order.paymentToken} />)
          commonPopup?.show()
        } else {
          toastError(<ToastError message={err?.message || 'Cannot create transaction'} />)
        }
      } finally {
        setReceiving(false)
      }
    }
  }

  return (
    <div className='rounded bg-white border border-sky-light relative popup-collection-trigger'>
      <div className='p-3'>
        <p className='text-xs'>{detail.dest.token_address}</p>
        <Button className='mt-4 w-full' onClick={handleReceive} isLoading={receiving}>
          <Trans>Claim</Trans>
        </Button>
      </div>
    </div>
  )
}

export default Item

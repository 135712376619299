import { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { useMintProducts } from 'queries/mint'
import { Product } from 'types/product'

export const useInfiniteMintProduct = () => {
  const [cursor, setCursor] = useState<number>(0)
  const [isEnd, setEnd] = useState(false)
  const [hideRefElement, setHideRefElement] = useState(false)
  const [showProducts, setShowProducts] = useState<Product[]>([])
  const { data: orders, isLoading, invalidate } = useMintProducts()
  const nextCursor = useRef<number>()

  const { ref, inView } = useInView({
    threshold: 0.5
  })

  useEffect(() => {
    setTimeout(() => {
      setHideRefElement(isLoading) // when loading, we will hide the ref element with some delay to make smoothy
    }, 600)
  }, [isLoading])

  useEffect(() => {
    if (orders?.data) {
      const list = orders.data
      if (cursor) {
        setShowProducts(old => {
          return [...old, ...list]
        })
      } else {
        setShowProducts(list)
      }

      if (orders.cursor) {
        nextCursor.current = orders.cursor
      } else {
        setEnd(true)
      }
    }
  }, [cursor, orders])

  // use ref to prevent infinite render if maybe
  useEffect(() => {
    if (inView) {
      setCursor(nextCursor.current || 0)
    }
  }, [inView])

  return { showProducts, isLoading, isEnd, ref, hideRefElement, invalidate }
}

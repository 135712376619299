import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'

import { useLocale } from 'hooks'
import { Classification, Product } from 'types/product'
import { useCommonPopup } from 'store/popup/hook'

import { ClassificationInfo, CoverImage } from 'components'
import EditModal from './EditModal'

type ItemProps = {
  product: Product
  invalidate: () => Promise<void>
}

const Item = ({ product, invalidate }: ItemProps) => {
  const locale = useLocale()
  const { popup, setContent } = useCommonPopup()

  const hanldeOpenEditModal = () => {
    setContent(<EditModal invalidate={invalidate} product={product} popup={popup} />)
    popup?.show()
  }

  const author = useMemo(() => (locale === 'en' ? product.author_name : product.author_name_jp), [locale, product])
  const title = useMemo(() => (locale === 'en' ? product.name : product.name_jp), [locale, product])
  const image = useMemo(
    () => product.assets_collections_urls?.marketplace_preview?.[0] || product.assets_urls?.[1],
    [product]
  )
  const classification = useMemo(() => product.rarity, [product])

  const renderEdition = useMemo(() => {
    if (product.order_count) {
      if (product.order_count === 1) {
        return (
          <p className='text-xs text-ink-lighter font-medium'>
            <Trans>Single Edition</Trans>
          </p>
        )
      }

      return (
        <p className='text-xs text-ink-lighter font-medium'>
          <Trans>Edition Size</Trans>: {product.order_count}
        </p>
      )
    }
  }, [product])

  return (
    <div className='rounded bg-white cursor-pointer border border-sky-light relative group'>
      <div
        className='bg-blue-200 rounded text-white absolute right-1 top-1 cursor-pointer opacity-0 group-hover:opacity-100 px-2'
        onClick={hanldeOpenEditModal}
      >
        <Trans>Edit</Trans>
      </div>
      <CoverImage className='h-[302px]' imageUrl={image || ''} />
      <div className='p-3'>
        <p className='text-xs text-ink-lighter'>{author}</p>
        <p className='font-medium py-[0.375rem]'>{title}</p>
        <div className='flex justify-between items-center'>
          <span className='text-xs'>
            <ClassificationInfo classification={classification as Classification} />
          </span>
          {renderEdition}
        </div>
      </div>
    </div>
  )
}

export default Item

export interface Detail {
  description: string
  external_url: string
  image: string
  name: string
  attributes: {
    trait_type: string
    value: string
  }[]
  tokenId: string
  isStaked?: boolean
}

export enum Rarity {
  Common = 'Common',
  Uncommon = 'Uncommon',
  Rare = 'Rare',
  Legendary = 'Legendary',
  Mythical = 'Mythical'
}

export interface Metadata {
  'Suicidal Rate': string
  Rarity: Rarity
}
